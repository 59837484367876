import React, { useEffect, useState } from 'react';
import Layout from '../layout/Layout';
import { useStateReducer } from "../../base/context/authContext";
import ApiClient from "../../base/api/client";
import Loader from "../../base/layout/Loader";


import AdminMgt from '../forms/AdminMgt'
import RecruitMgt from '../forms/RecruitMgt'
import SalesMgt from '../forms/SalesMgt'
import ProdMgt from '../forms/ProdMgt'
import OfficeDNFMgt from '../forms/OfficeDNFMgt'
import SafetyMgt from '../forms/SafetyMgt'
import HRMgt from '../forms/HRMgt'
import OpenMgt from '../forms/OpenMgt'
import CloseMgt from '../forms/CloseMgt'
import CloseBldgMgt from '../forms/CloseBldgMgt'
import LockerMgt from '../forms/LockerMgt'
import TrainingMgt from '../forms/TrainingMgt'
import ChisholmInventoryMgt from '../forms/ChisholmInventoryMgt'
import ShopInventoryMgt from '../forms/ShopInventoryMgt'
import LadderMgt from '../forms/LadderMgt'
import TruckGearMgt from '../forms/TruckGearMgt'
import LockerInventoryMgt from '../forms/LockerInventoryMgt'
import VehicleMgt from '../forms/VehicleMgt'
import EstSignMgt from '../forms/EstSignMgt'

const DailyReport = () => {
  const [{ user, userLoading }] = useStateReducer();
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [currentForm, setCurrentForm] = useState(null);
  const [adminMgtButton, setAdminMgtButton] = useState('button is-info');
  const [recruitMgtButton, setRecruitMgtButton] = useState('button is-info');
  const [salesMgtButton, setSalesMgtButton] = useState('button is-info');
  const [prodMgtButton, setProdMgtButton] = useState('button is-info');
  const [offDNFMgtButton, setOffDNFMgtButton] = useState('button is-info');
  const [safetyMgtButton, setSafetyMgtButton] = useState('button is-info');
  const [hrMgtButton, setHrMgtButton] = useState('button is-info');
  const [openMgtButton, setOpenMgtButton] = useState('button is-info');
  const [closeMgtButton, setCloseMgtButton] = useState('button is-info');
  const [closeBldgMgtButton, setCloseBldgMgtButton] = useState('button is-info');
  const [lockerMgtButton, setLockerMgtButton] = useState('button is-info');
  const [trainingMgtButton, setTrainingMgtButton] = useState('button is-info');
  const [chisholmInventoryMgtButton, setChisholmInventoryMgtButton] = useState('button is-info');
  const [shopInventoryMgtButton, setShopInventoryMgtButton] = useState('button is-info');
  const [ladderMgtButton, setLadderMgtButton] = useState('button is-info');
  const [truckGearMgtButton, setTruckGearMgtButton] = useState('button is-info');
  const [lockerInventoryMgtButton, setLockerInventoryMgtButton] = useState('button is-info');
  const [vehicleMgtButton, setVehicleMgtButton] = useState('button is-info');
  const [estSignMgtButton, setEstSignMgtButton] = useState('button is-info');

  useEffect(() => {
    if(user){
      console.log('User in App', user);
    }
  }, [user])


  useEffect(() => {
    if(currentForm){
      resetButtons();
      console.log('Current Form:', currentForm.type.name);
      const form = currentForm.type.name;
      if(form === 'AdminMgt'){
        (adminMgtButton !== 'button is-danger') && setAdminMgtButton('button is-warning');
      }else if(form === 'RecruitMgt'){
        console.log('Current Admin Mgt Label:', adminMgtButton);
        (recruitMgtButton !== 'button is-danger') && setRecruitMgtButton('button is-warning');
      }else if(form === 'SalesMgt'){
        (salesMgtButton !== 'button is-danger') && setSalesMgtButton('button is-warning');
      }else if(form === 'ProdMgt'){
        (prodMgtButton !== 'button is-danger') && setProdMgtButton('button is-warning');
      }else if(form === 'OfficeDNFMgt'){
        (offDNFMgtButton !== 'button is-danger') && setOffDNFMgtButton('button is-warning');
      }else if(form === 'SafetyMgt'){
        (safetyMgtButton !== 'button is-danger') && setSafetyMgtButton('button is-warning');
      }else if(form === 'HRMgt'){
        (hrMgtButton !== 'button is-danger') && setHrMgtButton('button is-warning');
      }else if(form === 'OpenMgt'){
        (openMgtButton !== 'button is-danger') && setOpenMgtButton('button is-warning');
      }else if(form === 'CloseMgt'){
        (closeMgtButton !== 'button is-danger') && setCloseMgtButton('button is-warning');
      }else if(form === 'CloseBldgMgt'){
        (closeBldgMgtButton !== 'button is-danger') && setCloseBldgMgtButton('button is-warning');
      }else if(form === 'LockerMgt'){
        (lockerMgtButton !== 'button is-danger') && setLockerMgtButton('button is-warning');
      }else if(form === 'TrainingMgt'){
        (trainingMgtButton !== 'button is-danger') && setTrainingMgtButton('button is-warning');
      }else if(form === 'ChisholmInventoryMgt'){
        (chisholmInventoryMgtButton !== 'button is-danger') && setChisholmInventoryMgtButton('button is-warning');
      }else if(form === 'ShopInventoryMgt'){
        (shopInventoryMgtButton !== 'button is-danger') && setShopInventoryMgtButton('button is-warning');
      }else if(form === 'LadderMgt'){
        (ladderMgtButton !== 'button is-danger') && setLadderMgtButton('button is-warning');
      }else if(form === 'TruckGearMgt'){
        (truckGearMgtButton !== 'button is-danger') && setTruckGearMgtButton('button is-warning');
      }else if(form === 'LockerInventoryMgt'){
        (lockerInventoryMgtButton !== 'button is-danger') && setLockerInventoryMgtButton('button is-warning');
      }else if(form === 'VehicleMgt'){
        (vehicleMgtButton !== 'button is-danger') && setVehicleMgtButton('button is-warning');
      }else if(form === 'EstSignMgt'){
        (estSignMgtButton !== 'button is-danger') && setEstSignMgtButton('button is-warning');
      }

    }
  }, [currentForm])


   const resetButtons = () => {
     (adminMgtButton !== 'button is-danger') && setAdminMgtButton('button is-info');
     (recruitMgtButton !== 'button is-danger') && setRecruitMgtButton('button is-info');
     (salesMgtButton !== 'button is-danger') && setSalesMgtButton('button is-info');
     (prodMgtButton !== 'button is-danger') && setProdMgtButton('button is-info');
     (offDNFMgtButton !== 'button is-danger') && setOffDNFMgtButton('button is-info');
     (safetyMgtButton !== 'button is-danger') && setSafetyMgtButton('button is-info');
     (hrMgtButton !== 'button is-danger') && setHrMgtButton('button is-info');
     (openMgtButton !== 'button is-danger') && setOpenMgtButton('button is-info');
     (closeMgtButton !== 'button is-danger') && setCloseMgtButton('button is-info');
     (closeBldgMgtButton !== 'button is-danger') && setCloseBldgMgtButton('button is-info');
     (lockerMgtButton !== 'button is-danger') && setLockerMgtButton('button is-info');
     (trainingMgtButton !== 'button is-danger') && setTrainingMgtButton('button is-info');
     (chisholmInventoryMgtButton !== 'button is-danger') && setChisholmInventoryMgtButton('button is-info');
     (shopInventoryMgtButton !== 'button is-danger') && setShopInventoryMgtButton('button is-info');
     (ladderMgtButton !== 'button is-danger') && setLadderMgtButton('button is-info');
     (truckGearMgtButton !== 'button is-danger') && setTruckGearMgtButton('button is-info');
     (lockerInventoryMgtButton !== 'button is-danger') && setLockerInventoryMgtButton('button is-info');
     (vehicleMgtButton !== 'button is-danger') && setVehicleMgtButton('button is-info');
     (estSignMgtButton !== 'button is-danger') && setEstSignMgtButton('button is-info');
  }

  const handleDailyReport = async (data) => {
    console.log("DATA for William", data);
    setShowLoader(true);
    const result = await ApiClient.post(`/api/daily_reports`, data);
    if(data.area === 'Admin Mgt'){
      setAdminMgtButton('button is-danger');
    }else if(data.area === 'Recruit Mgt'){
      setRecruitMgtButton('button is-danger');
    }else if(data.area === 'Sales Mgt'){
      setSalesMgtButton('button is-danger');
    }else if(data.area === 'Prod Mgt'){
      setProdMgtButton('button is-danger');
    }else if(data.area === 'Office DNF Mgt'){
      setOffDNFMgtButton('button is-danger');
    }else if(data.area === 'Safety Mgt'){
      setSafetyMgtButton('button is-danger');
    }else if(data.area === 'HR Mgt'){
      setHrMgtButton('button is-danger');
    }else if(data.area === 'Open Mgt'){
      setOpenMgtButton('button is-danger');
    }else if(data.area === 'Close Mgt'){
      setCloseMgtButton('button is-danger');
    }else if(data.area === 'Close Bldg Mgt'){
      setCloseBldgMgtButton('button is-danger');
    }else if(data.area === 'Locker Mgt'){
      setLockerMgtButton('button is-danger');
    }else if(data.area === 'Training Mgt'){
      setTrainingMgtButton('button is-danger');
    }else if(data.area === 'Chisholm Inventory Mgt'){
      setChisholmInventoryMgtButton('button is-danger');
    }else if(data.area === 'Shop Inventory Mgt'){
      setShopInventoryMgtButton('button is-danger');
    }else if(data.area === 'Ladder Mgt'){
      setLadderMgtButton('button is-danger');
    }else if(data.area === 'Truck Gear Mgt'){
      setTruckGearMgtButton('button is-danger');
    }else if(data.area === 'Locker Inventory Mgt'){
      setLockerInventoryMgtButton('button is-danger');
    }else if(data.area === 'Vehicle Mgt'){
      setVehicleMgtButton('button is-danger');
    }else if(data.area === 'EstSign Mgt'){
      setEstSignMgtButton('button is-danger');
    }

    setMessage(result.msg);
    setShowLoader(false);
  }


  const showButtons = () => {
    console.log('USER showButtons', user);
    if(user && user.hrid == 'HR00000001'){ //Roger
      return <div>
      <div className="field is-grouped">
        <p className="control">
          <button className={ adminMgtButton } onClick={ () => setCurrentForm(<AdminMgt handleDailyReport={handleDailyReport} user={user} />) }>Admin Mgt</button>
        </p>
        <p className="control">
          <button className={ recruitMgtButton } onClick={ () => setCurrentForm(<RecruitMgt handleDailyReport={handleDailyReport} user={user} />) }>Recruit Mgt</button>
        </p>
        <p className="control">
          <button className={ salesMgtButton } onClick={ () => setCurrentForm(<SalesMgt handleDailyReport={handleDailyReport} user={user} />) }>Sales Mgt</button>
        </p>
        <p className="control">
          <button className={ prodMgtButton } onClick={ () => setCurrentForm(<ProdMgt handleDailyReport={handleDailyReport} user={user} />) }>Prod Mgt</button>
        </p>
        <p className="control">
          <button className={ offDNFMgtButton } onClick={ () => setCurrentForm(<OfficeDNFMgt handleDailyReport={handleDailyReport} user={user} />) }>Office DNF Mgt</button>
        </p>
        <p className="control">
          <button className={ safetyMgtButton } onClick={ () => setCurrentForm(<SafetyMgt handleDailyReport={handleDailyReport} user={user} />) }>Safety Mgt</button>
        </p>
        <p className="control">
          <button className={ openMgtButton } onClick={ () => setCurrentForm(<OpenMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} />) }>Open Mgt(East)</button>
        </p>
      </div>
      <div className="field is-grouped">
        <p className="control">
          <button className={ closeMgtButton } onClick={ () => setCurrentForm(<CloseMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} />) }>Close Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ closeBldgMgtButton } onClick={ () => setCurrentForm(<CloseBldgMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} />) }>Close Bldg Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ lockerMgtButton } onClick={ () => setCurrentForm(<LockerMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} />) }>Locker Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ trainingMgtButton } onClick={ () => setCurrentForm(<TrainingMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} />) }>Training Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ chisholmInventoryMgtButton } onClick={ () => setCurrentForm(<ChisholmInventoryMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} />) }>Chisholm Inventory Mgt</button>
        </p>
        <p className="control">
          <button className={ shopInventoryMgtButton } onClick={ () => setCurrentForm(<ShopInventoryMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} />) }>Shop Inventory Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ ladderMgtButton } onClick={ () => setCurrentForm(<LadderMgt handleDailyReport={handleDailyReport} user={user} />) }>Ladder Mgt</button>
        </p>
      </div>
      <div className="field is-grouped">
        <p className="control">
          <button className={ truckGearMgtButton } onClick={ () => setCurrentForm(<TruckGearMgt handleDailyReport={handleDailyReport} user={user} />) }>Truck Gear Mgt</button>
        </p>
        <p className="control">
          <button className={ lockerInventoryMgtButton } onClick={ () => setCurrentForm(<LockerInventoryMgt handleDailyReport={handleDailyReport} user={user} />) }>Locker Inventory Mgt</button>
        </p>
        <p className="control">
          <button className={ vehicleMgtButton } onClick={ () => setCurrentForm(<VehicleMgt handleDailyReport={handleDailyReport} user={user} />) }>Vehicle Mgt</button>
        </p>
        <p className="control">
          <button className={ estSignMgtButton } onClick={ () => setCurrentForm(<EstSignMgt area={'East'} handleDailyReport={handleDailyReport} user={user} />) }>EstSign Mgt</button>
        </p>
      </div>
    </div>
    } else if(user && user.hrid == 'HR00005884'){ //Nat
      return <div className="field is-grouped">
        <p className="control">
          <button className={ prodMgtButton } onClick={ () => setCurrentForm(<ProdMgt handleDailyReport={handleDailyReport} user={user} />) }>Prod Mgt</button>
        </p>
        <p className="control">
          <button className={ officeDNFMgtButton } onClick={ () => setCurrentForm(<OfficeDNFMgt handleDailyReport={handleDailyReport} user={user} />) }>Office DNF Mgt</button>
        </p>
        <p className="control">
          <button className={ hrMgtButton } onClick={ () => setCurrentForm(<HRMgt handleDailyReport={handleDailyReport} user={user} />) }>HR Mgt</button>
        </p>
        <p className="control">
          <button className={ safetyMgtButton } onClick={ () => setCurrentForm(<SafetyMgt handleDailyReport={handleDailyReport} user={user} />) }>Safety Mgt</button>
        </p>
        <p className="control">
          <button className={ closeMgtButton } onClick={ () => setCurrentForm(<CloseMgt area={'(West)'} handleDailyReport={handleDailyReport} user={user} />) }>Close Mgt(West)</button>
        </p>
        <p className="control">
          <button className={ lockerMgtButton } onClick={ () => setCurrentForm(<LockerMgt area={'(West)'} handleDailyReport={handleDailyReport} user={user} />) }>Locker Mgt(West)</button>
        </p>
        <p className="control">
          <button className={ shopInventoryMgtButton } onClick={ () => setCurrentForm(<ShopInventoryMgt area={'(West)'} handleDailyReport={handleDailyReport} user={user} />) }>Shop Inventory Mgt</button>
        </p>
      </div>

    } else if(user && user.hrid == 'HR00006242'){ // Hannah
      return <div className="field is-grouped">
        <p className="control">
          <button className={ adminMgtButton } onClick={ () => setCurrentForm(<AdminMgt handleDailyReport={handleDailyReport} user={user} />) }>Admin Mgt</button>
        </p>
        <p className="control">
          <button className={ recruitMgtButton } onClick={ () => setCurrentForm(<RecruitMgt handleDailyReport={handleDailyReport} user={user} />) }>Recruit Mgt</button>
        </p>
        <p className="control">
          <button className={ salesMgtButton } onClick={ () => setCurrentForm(<SalesMgt handleDailyReport={handleDailyReport} user={user} />) }>Sales Mgt</button>
        </p>
        <p className="control">
          <button className={ closeBldgMgtButton } onClick={ () => setCurrentForm(<CloseBldgMgt area={'(West)'} handleDailyReport={handleDailyReport} user={user} />) }>Close Bldg Mgt(East)</button>
        </p>
      </div>
    } else if(user && user.hrid == 'HR00000429'){ // Shantz
      return <div className="field is-grouped">
      </div>
    } else if(user && user.hrid == 'HR00000429'){ // Noel
      return <div className="field is-grouped">
        <p className="control">
          <button className={ openMgtButton } onClick={ () => setCurrentForm(<OpenMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} />) }>Open Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ lockerMgtButton } onClick={ () => setCurrentForm(<LockerMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} />) }>Locker Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ trainingMgtButton } onClick={ () => setCurrentForm(<TrainingMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} />) }>Training Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ shopInventoryMgtButton } onClick={ () => setCurrentForm(<ShopInventoryMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} />) }>Shop Inventory Mgt</button>
        </p>
      </div>
    } else if(user && user.hrid == 'HR00000429'){ // Juwan
      return <div className="field is-grouped">
        <p className="control">
          <button className={ ladderMgtButton } onClick={ () => setCurrentForm(<LadderMgt handleDailyReport={handleDailyReport} user={user} />) }>Ladder Mgt</button>
        </p>
      </div>
    } else if(user && user.hrid == 'HR00000429'){ // Malik
      return <div className="field is-grouped">
        <p className="control">
          <button className={ trainingMgtButton } onClick={ () => setCurrentForm(<TrainingMgt area={'(West)'} handleDailyReport={handleDailyReport} user={user} />) }>Training Mgt(West)</button>
        </p>
      </div>
    }else if(user && user.hrid == 'HR00000429'){ // Ryansh
      return <div className="field is-grouped">
        <p className="control">
          <button className={ openMgtButton } onClick={ () => setCurrentForm(<OpenMgt area={'(West)'} handleDailyReport={handleDailyReport} user={user} />) }>Open Mgt(East)</button>
        </p>
      </div>
    } else if(user && user.hrid == 'HR00000429'){ // Jack
      return <div className="field is-grouped">
        <p className="control">
          <button className={ closeMgtButton } onClick={ () => setCurrentForm(<CloseMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} />) }>Close Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ closeBldgMgtButton } onClick={ () => setCurrentForm(<CloseBldgMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} />) }>Close Bldg Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ chisholmInventoryMgtButton } onClick={ () => setCurrentForm(<ChisholmInventoryMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} />) }>Chisholm Inventory Mgt</button>
        </p>
        <p className="control">
          <button className={ lockerInventoryMgtButton } onClick={ () => setCurrentForm(<LockerInventoryMgt handleDailyReport={handleDailyReport} user={user} />) }>Locker Inventory Mgt</button>
        </p>
        <p className="control">
          <button className={ vehicleMgtButton } onClick={ () => setCurrentForm(<VehicleMgt handleDailyReport={handleDailyReport} user={user} />) }>Vehicle Mgt</button>
        </p>
      </div>
    }
  }


  return (
    <Layout>
      <section className='section'>
        <div className='container'>
        { user && showButtons() }

        { currentForm ? currentForm : '' }

        </div>
      </section>
    </Layout>
  )

};

export default DailyReport;
